import {GetTokenInfo, GetTokenInfoResponse} from "./GetTokenInfo";
import {GetSnip721Params, Snip721TokenInfo} from "./GetSnip721Params";
import {Snip721GetTokens as GetTokens} from "./GetTokens";
import {Snip721Send as Send} from "./send";
import {Snip721Reveal} from "./reveal";

const Snip721 = {
    GetTokenInfo,
    GetSnip721Params,
    GetTokens,
    Send,
    Snip721Reveal
}

export default Snip721;
