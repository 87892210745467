import {CosmWasmClient} from "secretjs";
import {Permit} from "../permit";


export interface Trait {
    /// indicates how a trait should be displayed
    display_type?: string,
    /// name of the trait
    trait_type?: string,
    /// trait value
    value: string,
    /// optional max value for numerical traits
    max_value?: string,
}

export interface Extension {
     image?: string,
    /// raw SVG image data (not recommended). Only use this if you're not including the image parameter
     image_data?: string,
    /// url to allow users to view the item on your site
     external_url?: string,
    /// item description
     description?: string,
    /// name of the item
     name?: string,
    /// item attributes
    attributes?: Trait[],
    /// background color represented as a six-character hexadecimal without a pre-pended #
    // background_color: Option<String>,
    /// url to a multimedia attachment
  //   animation_url: Option<String>,
    /// url to a YouTube video
//     youtube_url: Option<String>,
    /// media files as specified on Stashh that allows for basic authenticatiion and decryption keys.
    /// Most of the above is used for bridging lic eth NFT metadata easily, whereas `media` will be used
    /// when minting NFTs on Stashh
    // media: Option<Vec<MediaFile>>,
    /// a select list of trait_types that are in the private metadata.  This will only ever be used
    /// in lic metadata
    protected_attributes: string[],
}


/// token metadata
export interface Metadata {
    /// optional uri for off-chain metadata.  This should be prefixed with `http://`, `https://`, `ipfs://`, or
    /// `ar://`.  Only use this if you are not using `extension`
    token_uri?: string,
        /// optional on-chain metadata.  Only use this if you are not using `token_uri`
    extension?: Extension,
}

export interface Cw721OwnerOfResponse {
    /// Owner of the token if permitted to view it
    owner?: string,
        /// list of addresses approved to transfer this token
    approvals: string[],
}

export interface GetTokenInfoResponse {
    all_nft_info: {
        access: Cw721OwnerOfResponse,
        info?: Metadata,
    },
    token_id: string,
}

export const GetTokenInfo = async (
    secretjs: CosmWasmClient,
    token: string,
    address: string,
    token_id: string,
    auth: {
        key?: string,
        permit?: Permit,
    }
): Promise<GetTokenInfoResponse | undefined> => {
    const {key, permit} = auth;
    let balanceResponse: GetTokenInfoResponse;

    try {
        if (permit) {
            balanceResponse = await secretjs.queryContractSmart(token, {
                with_permit: {
                    permit,
                    // viewer: "address_of_the_querier_if_different_from_owner",
                    query: {
                        all_nft_info: {
                            token_id,
                        },
                    },
                    // limit: 10
                },
            });
        } else {
            balanceResponse = await secretjs.queryContractSmart(token, {
                all_nft_info: {
                    token_id
                },
            });
        }

        console.log(`nft details response: ${JSON.stringify(balanceResponse)}`);

        balanceResponse.token_id = token_id;
        return balanceResponse;
    } catch (e) {
        console.error(`Failed to get details: ${e}`);
        return undefined;
    }
}
