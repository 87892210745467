import {
    BottomNavigation,
    Card,
    CardMedia,
    CircularProgress,
    Grid,
    Paper,
    Skeleton,
    Typography
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import React, {useEffect, useState} from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import {getAllNftDetails, getNftDetails, mintToken, revealToken, useSecret} from './hooks/useSecret';
import {sleep} from "./scrt/utils";
import {Grid3x3Outlined, Image} from "@material-ui/icons";
import {MintingModal} from "./components/MintingModal";

const Root = styled('div')(({ theme }) => ({
        flexGrow: 1,
        marginTop: 20,
        height: 1024,
    }))

const MyDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '20px'
}))


// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         marginTop: 20,
//         height: 1024,
//     },
//     paper: {
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         //color: theme.palette.text.secondary,
//     },
//     // scrtColor: {
//     //     color: theme.palette.primary,
//     // },
//     // subtitle: {
//     //     color: theme.palette.primary,
//     // },
//     card: {
//         minWidth: 275,
//     },
//     titleText: {
//         fontSize: 14,
//     },
// }));

function MainPage() {
    const {scrtBalance, setNftAddress, userNfts, secretjs, account, refreshBalances, baconBalance} = useSecret();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nftUrls, setNftUrls] = useState<{token_id?: string, uri?: string, penis_size?: number}[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [pigMintParams, setPigMintParams] =
        useState<{pig_number?: string, bacon_amount?: string}>({pig_number: "", bacon_amount: ""});

    useEffect(() => {
        setNftAddress(process.env.REACT_APP_NFT_ADDRESS);
    }, []);

    useEffect(() => {
        if (secretjs) {
            refreshBalances();
        }
    }, [secretjs]);

    useEffect(() => {

        const func = async () => {
            if (secretjs && account) {
                let result = await getAllNftDetails(userNfts, secretjs, account, process.env.REACT_APP_NFT_ADDRESS || "yo");
                let urls = result.map(a => {
                    if (a) {
                        return {
                            token_id: a.token_id,
                            uri: a.all_nft_info.info?.token_uri || a.all_nft_info?.info?.extension?.image,
                            penis_size: Number(
                                a.all_nft_info?.info?.extension?.attributes &&
                                a.all_nft_info?.info?.extension?.attributes[0]?.value) || undefined,
                        }
                    }
                    return {};
                }).filter(a => !!a);
                setNftUrls(urls);
            }
        }

        func();

        // await getNftDetails(, nftId);
    }, [userNfts, secretjs, account])

    // className="App"
    return (
        <Root>
            <MintingModal
                open={openModal}
                pigMintParams={pigMintParams}
                handleClose={(event, reason) => {setOpenModal(false)}}/>
            {/*<div hidden={!isLoading}>*/}
            {/*    <CircularProgress />*/}
            {/*</div>*/}
            <MyDiv>
                    <Paper>
                    <Card>
                        HELLO - {account}
                    </Card>

                    <Card>
                        This is your balance - {new Intl.NumberFormat('en-US', {}).format(+Number(scrtBalance) / 1e6)}
                    </Card>
                    <Card>
                        These are your nfts - {userNfts.map(nft => `${nft},`)}
                    </Card>
                    <Card>
                        This is your bacon - {new Intl.NumberFormat('en-US', {}).format(+ (Number(baconBalance) || 0) / 1e6)}
                    </Card>
                    </Paper>
                    <Button onClick={async () => {
                        setIsLoading(true);
                        let result = await mintToken(secretjs);

                        await sleep(2000);

                        if (result?.minted) {
                            setPigMintParams({pig_number: result.minted, bacon_amount: result?.bacon})
                            setOpenModal(true);
                            refreshBalances();
                        }

                        setIsLoading(false);
                    }

                    }>
                        Mint
                    </Button>
            </MyDiv>
            <Grid container spacing={3}>
                {
                    nftUrls.map((nftId) => {
                    return (<div key={nftId.token_id}>
                        <Grid item xs={6}>
                            <Card style={{width: "300px", textAlign: "center", background: "linear-gradient(210.96deg, rgba(36, 33, 81, 0.74) 0.01%, rgba(38, 35, 83, 0.78) 42.05%, rgba(28, 26, 64, 0.51) 104.81%)"}}>
                            <CardContent>

                            <Typography variant={'body2'} style={{ margin: 5 }} color="common.white">
                               Token ID: {nftId.token_id}
                            </Typography>
                                {nftId?.penis_size ?
                                    (<Typography variant={'body2'} style={{ margin: 5 }} color="common.white">
                                        Penis Size: { nftId.penis_size > 2500 ? "HUGE PENIS" : "MICRO PENIS" }
                                    </Typography>)
                                : null
                                }
                                { !nftId?.penis_size ?
                                    <Button onClick={async () => {
                                        await revealToken(secretjs, process.env.REACT_APP_NFT_ADDRESS, nftId.token_id);
                                        await sleep(1000);
                                        await refreshBalances();
                                    }

                                    }>
                                        Reveal
                                    </Button>
                                    : null }
                                </CardContent>
                            <CardMedia image={nftId.uri} style={{height: "100px"}}/>

                            </Card>
                        </Grid>
                    </div>);
                })}
                {isLoading ?
                    (
                        <>
                            <Skeleton sx={{marginLeft: "10px", marginTop: "20px"}} variant="rectangular" width={300}
                                      height={200}/>
                        </>
                    )
                    :
                    null}
            </Grid>
        </Root>
    );
}

//

export default MainPage;
