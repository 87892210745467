import { CosmWasmClient } from 'secretjs';

export const GetContractCodeHash = async ({
                                              secretjs,
                                              address,
                                          }: {
    secretjs: CosmWasmClient;
    address: string;
}): Promise<string> => {
    return await secretjs.getCodeHashByContractAddr(address);
};

export const sleep = (ms: number) => new Promise((accept) => setTimeout(accept, ms));

