import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const style = {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
} as const ;

export const toDisplayAmount = (amount: string) => {
    return new Intl.NumberFormat('en-US', {}).format(+ (Number(amount) || 0) / 1e6);
}

//pig_number: number, bonus_bacon: number,
export const MintingModal = (props:
                                 {open: boolean,
                                     pigMintParams: {pig_number?: string, bacon_amount?: string},
                                     handleClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)}) => {
    // const [open, setOpen] = React.useState();
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Congrats! You minted a new box!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Pig #{props.pigMintParams.pig_number} {props.pigMintParams.bacon_amount ? `and unlocked a bonus ${toDisplayAmount(props.pigMintParams.bacon_amount)} bacon!` : null}
                    </Typography>
                    <Button onClick={() => {
                        props.handleClose({}, "backdropClick");
                    }}>
                        Thanks!
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};
//                        Pig #{props.pig_number}, and unlocked a bonus {props.bonus_bacon} bacon!
