import React, {createContext, useContext, useEffect, useState} from 'react';
import {getFromLS, setToLS} from '../utils/storage';
import {SigningCosmWasmClient} from 'secretjs';
import {Snip20GetBalance} from '../scrt/snip20';
import Snip721 from '../scrt/snip721/index';
import {Snip721GetTokensResponse} from "../scrt/snip721/GetTokens";
import {GetTokenInfoResponse} from "../scrt/snip721/GetTokenInfo";
import {sleep} from "../scrt/utils";
import {Snip721RevealResponse} from "../scrt/snip721/reveal";
// import { Snip20GetBalance } from '../snip721';

const MINTING_CONTRACT = process.env.REACT_APP_MINT_CONTRACT_ADDRESS || ""
const BALANCE_REFRESH_TIME = 2000;
// import {
//     advanceWindowCommand,
//     deposit,
//     executeVote,
//     queryActiveProposals,
//     queryClaim,
//     queryExchangeRate,
//     queryProposal,
//     viewVote,
//     withdraw,
// } from '../cash';
// import { stakingContract, tokenContract, votingContract } from '../utils/consts';

// export const stopContract = async ({ secretNetwork }) => {
//     try {
//         await secretNetwork.execute('secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek', {
//             set_contract_status: { level: 'stop_all' },
//         });
//     } catch (e) {
//         console.log(`Failed to deposit ${e}`);
//     }
//     return null;
// };

export const mintToken = async (secretNetwork: SigningCosmWasmClient | undefined): Promise<{ minted?: string, bacon?: string } | undefined> => {
    if (!secretNetwork) {
        return undefined;
    }
    try {
        let result = await secretNetwork.execute(MINTING_CONTRACT, {
            mint: {},
        }, "",
            [{denom: 'uscrt', amount: "1000000"}]);

        console.log(`MINT RESPONSE: ${JSON.stringify(result)}`);

        let logs = result.logs[0].events.find(e => e.type === "wasm")?.attributes || [];

        return {
            minted: logs.find(attr => attr.key.trim() === "minted")?.value,
            bacon: logs.find(attr => attr.key.trim() === "bacon_awarded")?.value,
        }

    } catch (e) {
        console.log(`Failed to deposit ${e}`);
    }
    return undefined;
};

export const mintBatch = async (secretNetwork: SigningCosmWasmClient, amount: number) => {
    try {
        await secretNetwork.execute(MINTING_CONTRACT, {
            mint: {},
        });
    } catch (e) {
        console.log(`Failed to deposit: ${e}`);
    }
};

//
// export const withdrawDSCRT = async (secretjs, amountDscrt) => {
//     try {
//         await withdraw({
//             secretNetwork: secretjs,
//             amount: Number(amountDscrt) * 1e6,
//             contractAddress: stakingContract,
//             tokenContractAddress: tokenContract,
//         });
//     } catch (e) {
//         console.log(`Failed to withdraw: ${e}`);
//     }
// };
//
// export const advanceWindow = async (secretjs) => {
//     if (secretjs) {
//         console.log('advancing window');
//         await advanceWindowCommand({ secretjs, stakingContract });
//     }
// };
//
// export const queryClaims = async (secretjs, account) => {
//     return await queryClaim({
//         secretNetwork: secretjs,
//         contractAddress: stakingContract,
//         account,
//     });
// };
//
// const XRATE_REFRESH_TIME = 20000;
// const BALANCE_REFRESH_TIME = 10000;
export const getNftDetails = async (secretjs: SigningCosmWasmClient | undefined, account: string | undefined, token: string | undefined, token_id: string): Promise<GetTokenInfoResponse | undefined> => {
    if (secretjs) {
        return await Snip721.GetTokenInfo(secretjs, token || "yo", account || "yo", token_id || "yo", {});
    }
    return undefined;
}

export const getAllNftDetails = async (token_ids: string[], secretjs: SigningCosmWasmClient, account: string, token: string) => {
    let promises = []
    for (const id of token_ids) {
        promises.push(getNftDetails(secretjs, account, token, id));
    }

    return await Promise.all(promises);
}


export const getNfts = async (secretjs: SigningCosmWasmClient | undefined, account: string, token?: string, permit?: string): Promise<Snip721GetTokensResponse | undefined> => {
    if (secretjs) {
        return await Snip721.GetTokens(secretjs, token || "", account, {permit});
    }
}

export const revealToken = async (secretjs: SigningCosmWasmClient | undefined, token?: string, token_id?: string): Promise<Snip721RevealResponse | undefined> => {
    if (secretjs && token && token_id) {
        return await Snip721.Snip721Reveal(secretjs, token, token_id);
    }

}

const getScrtBalance = async (secretjs: SigningCosmWasmClient | undefined, account: string): Promise<string | undefined> => {
    if (secretjs) {
        let accounts = await secretjs.getAccount(account);

        if ((accounts?.balance?.length || 0) > 0) {
            let balance = accounts?.balance[0]?.amount;
            if (!isNaN(Number(balance))) {
                return balance;
            }
        }
    }
};

const SecretJSContext = createContext<{
    secretjs: SigningCosmWasmClient | undefined,
    secretLoaded: boolean,
    setNftAddress: Function,
    refreshBalances: Function,
    account: string | undefined,
    scrtBalance: string | undefined,
    userNfts: string[],
    baconBalance: string | undefined
    }
>({
    secretjs: undefined,
    secretLoaded: false,
    setNftAddress: () => {},
    refreshBalances: () => {},
    account: undefined,
    scrtBalance: undefined,
    userNfts: [],
    baconBalance: undefined,
});

export const UNLOCK_TOKEN = 'UNLOCK';

export const SecretContext: React.FC<React.ReactNode> = (props) => {
    const [secretjs, setSecretJS] = useState<SigningCosmWasmClient | undefined>(undefined);
    const [account, setLocalAccount] = useState<string>('');
    const [secretLoaded, setSecretLoaded] = useState<boolean>(false);
    const [baconBalance, setBaconBalance] = useState<string | undefined>(undefined);
    const [userNfts, setUserNfts] = useState<string[]>([]);
    const [selectedNft, setSelectedToken] = useState<string | undefined>(undefined);
    const [scrtBalance, setScrtBalance] = useState<string | undefined>(undefined);

    //    const [dscrtBalance, setDScrtBalance] = useState(undefined);
    //  const [dScrtDisabled, setdScrtDisabled] = useState(true);
    //   const [claims, setClaims] = useState([]);
    //   const [proposals, setProposals] = useState([]);
    //   const [votes, setVotes] = useState({});
    // const [exchangeRate, setExchangeRate] = useState(undefined);

    const setAccount = (account: string) => {
        setToLS('account', account);
        setLocalAccount(account);
    };

    const getAccount = (): string | undefined => {
        return getFromLS('account');
    };

    const setNftAddress = (token: string) => {
        setSelectedToken(token);
    }

    const getBaconBalance = async () => {
        if (secretjs) {

            return await Snip20GetBalance({
                secretjs: secretjs,
                address: account,
                token: process.env.REACT_APP_BACON_ADDRESS || "yo",
            });
        }
    };

    const refreshBalances = async () => {
        await Promise.all([
            getScrtBalance(secretjs, account).then().then(result => {
                if (result) {
                    setScrtBalance(result);
                }
        }),
            getNfts(secretjs, account, selectedNft).then(token_list => setUserNfts(token_list?.token_list.tokens || [])),
            getBaconBalance().then(result => setBaconBalance(result))
        ]);
    };

    useEffect(() => {
        if (selectedNft && secretjs) {
            getNfts(secretjs, account, selectedNft, "").then(
                (token_list) => {
                    //setNftBalance(token_list?.token_list.tokens.length);
                    setUserNfts(token_list?.token_list.tokens || []);
                }
            );
        }
    }, [account, secretjs, selectedNft])

    useEffect(() => {
        // first load, instantly refresh balances
        getScrtBalance(secretjs, account).then(
            (balance) => {
                if (balance) {
                    setScrtBalance(balance);
                }
            });

        const interval2 = setInterval(
            async () => {
                let balance = await getScrtBalance(secretjs, account)
                if (balance) {
                    setScrtBalance(balance);
                }
            }, BALANCE_REFRESH_TIME);
        //const interval3 = setInterval(getDScrtBalance, BALANCE_REFRESH_TIME);

        return () => {
            clearInterval(interval2);
            //clearInterval(interval3);
        };
    }, [secretjs, account]);

    useEffect(() => {
        const setupSecretJS = async () => {

            // Wait for Keplr to be injected to the page
            // @ts-ignore
            while (!window.keplr && !window.getOfflineSigner && !window.getEnigmaUtils) {
                await sleep(10);
            }

            if (process.env.REACT_APP_CHAIN_ID !== "secret-4") {
                    // @ts-ignore
                await window.keplr.experimentalSuggestChain({
                        chainId: process.env.REACT_APP_CHAIN_ID,
                        chainName: process.env.REACT_APP_CHAIN_NAME || "secretdev",
                        rpc: process.env.REACT_APP_RPC_SERVICE,
                        rest: process.env.REACT_APP_LCD_SERVICE,
                        bip44: {
                            coinType: 529,
                        },
                        coinType: 529,
                        stakeCurrency: {
                            coinDenom: 'SCRT',
                            coinMinimalDenom: 'uscrt',
                            coinDecimals: 6,
                        },
                        bech32Config: {
                            bech32PrefixAccAddr: 'secret',
                            bech32PrefixAccPub: 'secretpub',
                            bech32PrefixValAddr: 'secretvaloper',
                            bech32PrefixValPub: 'secretvaloperpub',
                            bech32PrefixConsAddr: 'secretvalcons',
                            bech32PrefixConsPub: 'secretvalconspub',
                        },
                        currencies: [
                            {
                                coinDenom: 'SCRT',
                                coinMinimalDenom: 'uscrt',
                                coinDecimals: 6,
                            },
                        ],
                        feeCurrencies: [
                            {
                                coinDenom: 'SCRT',
                                coinMinimalDenom: 'uscrt',
                                coinDecimals: 6,
                            },
                        ],
                        gasPriceStep: {
                            low: 0.01,
                            average: 0.25,
                            high: 0.25,
                        },
                        features: ['secretwasm'],
                    });
            }

            // @ts-ignore
            await window.keplr.enable(process.env.REACT_APP_CHAIN_ID);

            // Setup SecretJS with Keplr's OfflineSigner
            // This pops-up a window for the user to sign on each tx we sent
            // @ts-ignore
            let keplrOfflineSigner = window.getOfflineSigner(process.env.REACT_APP_CHAIN_ID);
            const accounts = await keplrOfflineSigner.getAccounts();

            let secretjs = new SigningCosmWasmClient(
                process.env.REACT_APP_LCD_SERVICE || 'http://20.127.18.96:1317',
                accounts[0].address,
                keplrOfflineSigner,
                // @ts-ignore
                window.getEnigmaUtils(process.env.REACT_APP_CHAIN_ID),
                {
                    // 300k - Max gas units we're willing to use for init
                    init: {
                        amount: [{ amount: '1', denom: 'uscrt' }],
                        gas: '1200000',
                    },
                    // 300k - Max gas units we're willing to use for exec
                    exec: {
                        amount: [{ amount: '1', denom: 'uscrt' }],
                        gas: '1200000',
                    },
                },
            );
            setAccount(accounts[0].address);
            setSecretJS(secretjs);
            setSecretLoaded(true);
        };

        setupSecretJS().then(() => {});
    }, []);

    return (
        <SecretJSContext.Provider
            value={{
                secretjs,
                scrtBalance,
                refreshBalances,
                setNftAddress,
                secretLoaded,
                account,
                userNfts,
                baconBalance

    }}
>
    {props.children}
    </SecretJSContext.Provider>
);
    // return {
    //     secretjs,
    //     secretLoaded,
    //     getSnip20Balance,
    //     account,
    //     exchangeRate,
    // };
};

export const useSecret = () => useContext(SecretJSContext);
